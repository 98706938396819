import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var bookCashDeskPayment = function (user) {
    var hasPermissionCreateBookCashDeskPayment = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK_CASH_DESK_PAYMENT, MethodCode.CREATE);
    var hasPermissionListBookCashDeskPayment = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK_CASH_DESK_PAYMENT, MethodCode.LIST);
    var hasPermissionViewBookCashDeskPayment = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK_CASH_DESK_PAYMENT, MethodCode.VIEW);
    var hasPermissionDeleteBookCashDeskPayment = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK_CASH_DESK_PAYMENT, MethodCode.DELETE);
    return {
        hasPermissionCreateBookCashDeskPayment: hasPermissionCreateBookCashDeskPayment,
        hasPermissionListBookCashDeskPayment: hasPermissionListBookCashDeskPayment,
        hasPermissionViewBookCashDeskPayment: hasPermissionViewBookCashDeskPayment,
        hasPermissionDeleteBookCashDeskPayment: hasPermissionDeleteBookCashDeskPayment,
    };
};
export default bookCashDeskPayment;
